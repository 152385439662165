import dayjs, { Dayjs } from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import download from "downloadjs";

dayjs.extend(isSameOrBefore);

import { ApiClassesService } from "../services/apiClassesService";
import { Holiday } from "./holiday";
import { Vacation } from "./vacation";
import { User } from "./user";
import { Participant } from "./participant";
import { CourseGroup } from "./courseGroup";
import { HubspotLeadStatus } from "../constants";

export interface ICoursePerformanceReport {
  homeworkAvg: number;
  absenceAvg: number;
  presenceAvg: number;
  gradeAvg: number;
  dropouts: number;
  dropoutRate: number;
}

export interface ICoursesPerformanceReport {
  dropouts: number;
  dropoutRate: number;
}

export class CourseModuleDates {
  moduleName: string;
  startDate: Dayjs;
  endDate: Dayjs;
  teachingDays: number;
  countVacationDays: number;
  countHolidays: number;

  constructor(data: Record<string, unknown>) {
    this.moduleName = String(data.moduleName);
    this.startDate = dayjs(data.startDate as string);
    this.endDate = dayjs(data.endDate as string);
    this.teachingDays = Number(data.teachingDays);
    this.countVacationDays = Number(data.countVacationDays);
    this.countHolidays = Number(data.countHolidays);
  }
}

export interface ICourse {
  id: number;
  title: string;
  teacher?: Array<User>;
  teachingAssistants?: Array<User>;
  classManager?: Array<User>;
  careerCoaches?: Array<User>;
  participants: Array<Participant>;
  startsAt?: Dayjs | string;
  endsAt?: Dayjs | string;
  courseBook: number;
  tutoringBook: number;
  program: number;
  maxVacationDays: number;
  performanceTrackingTemplate: number;
  vacationDaysForPersonalUsage: number;
  holidays?: Array<Holiday>;
  vacations?: Array<Vacation>;
  groups?: Array<CourseGroup>;
  hasAccess?: boolean;
  courseDates?: Array<CourseModuleDates>;
}

export class Course implements ICourse {
  id: number;
  title: string;
  teacher: Array<User> = [];
  teachingAssistants: Array<User> = [];
  classManager: Array<User> = [];
  careerCoaches: Array<User> = [];
  participants: Array<Participant> = [];
  activeParticipants: Array<Participant> = [];
  lateStartParticipants: Array<Participant> = [];
  dropoutParticipants: Array<Participant> = [];
  noAttendanceParticipants: Array<Participant> = [];
  dealNotExtendedParticipants: Array<Participant> = [];
  graduatedParticipants: Array<Participant> = [];
  startsAt: Dayjs;
  endsAt: Dayjs;
  courseBook: number;
  tutoringBook: number;
  program: number;
  maxVacationDays: number;
  performanceTrackingTemplate: number;
  vacationDaysForPersonalUsage: number;
  holidays: Array<Holiday> = [];
  vacations: Array<Vacation> = [];
  groups: Array<CourseGroup> = [];
  hasAccess?: boolean;
  courseDates: Array<CourseModuleDates> = [];

  constructor(data: ICourse) {

    this.id = data.id;
    this.title = String(data.title);
    this.startsAt = dayjs(new Date(data.startsAt as string));
    this.endsAt = dayjs(new Date(data.endsAt as string));
    this.courseBook = data.courseBook;
    this.tutoringBook = data.tutoringBook;
    this.program = data.program;
    this.performanceTrackingTemplate = data.performanceTrackingTemplate;
    this.hasAccess = data.hasAccess;
    this.vacationDaysForPersonalUsage = data.vacationDaysForPersonalUsage;
    this.maxVacationDays = data.maxVacationDays;

    if (Array.isArray(data.teacher)) {
      data.teacher.map(d => this.teacher.push(new User(d)));
    }

    if (Array.isArray(data.teachingAssistants)) {
      data.teachingAssistants.map(d => this.teachingAssistants.push(new User(d)));
    }

    if (Array.isArray(data.classManager)) {
      data.classManager.map(d => this.classManager.push(new User(d)));
    }

    if (Array.isArray(data.careerCoaches)) {
      data.careerCoaches.map(d => this.careerCoaches.push(new User(d)));
    }

    if (Array.isArray(data.participants)) {
      const currentDate = dayjs();
      data.participants.forEach(d => {
        this.participants.push(new Participant(d));
        if (dayjs(d.entryDate).isSameOrBefore(currentDate, "day") && d.hubspotLeadStatus === HubspotLeadStatus.ACTIVE) {
          this.activeParticipants.push(new Participant(d));
        } else if (dayjs(d.entryDate).isAfter(currentDate, "day") && d.hubspotLeadStatus === HubspotLeadStatus.ACTIVE) {
          this.lateStartParticipants.push(new Participant(d));
        } else if (d.hubspotLeadStatus === HubspotLeadStatus.DROPOUT) {
          this.dropoutParticipants.push(new Participant(d));
        } else if (d.hubspotLeadStatus === HubspotLeadStatus.NO_ATTENDANCE) {
          this.noAttendanceParticipants.push(new Participant(d));
        } else if (d.hubspotLeadStatus === HubspotLeadStatus.DEAL_NOT_EXTENDED) {
          this.dealNotExtendedParticipants.push(new Participant(d));
        } else if (d.hubspotLeadStatus === HubspotLeadStatus.GRADUATED) {
          this.graduatedParticipants.push(new Participant(d));
        }
      });
    }

    if (Array.isArray(data.holidays)) {
      data.holidays.map(d => this.holidays.push(new Holiday(d)));
    }

    if (Array.isArray(data.vacations)) {
      data.vacations.map(d => this.vacations.push(new Vacation(d)));
    }

    if (Array.isArray(data.groups)) {
      data.groups.map(d => this.groups.push(new CourseGroup(d)));
    }

    if (Array.isArray(data.courseDates)) {
      data.courseDates.map(d => this.courseDates.push(new CourseModuleDates(d as unknown as Record<string, unknown>)));
    }
  }

  /**
   * Calls the backend to create a bulk report of all participants of this course.
   *
   * It will just make a backend call - the backend sends the reports to the requester afterward.
   */
  public async downloadPresenceTrackingReports(period: Date) {
    const res = await ApiClassesService.createCoursePresenceReports(
      this.title,
      (period.getMonth() + 1).toString(),
      period.getFullYear().toString()
    );
    if (res.status === 200) {
      const fileName = `${this.title}.zip`;
      download(res.data, fileName, "application/zip");
    }
  }

  /**
   * Calls the backend to fetch a bulk report of all participants of this course.
   *
   * It will just make a backend call - the backend sends the reports to the requester afterward.
   */
  public async downloadPerformanceQuarterReports(quarter: number) {
    const res = await ApiClassesService.fetchCoursePerformanceQuarterReports(this.title, quarter);
    if (res.status === 200) {
      const fileName = `${this.title}.zip`;
      download(res.data, fileName, "application/zip");
    }
  }

  /**
   * Calls the backend to create or update the presence tracking reports for the whole course.
   *
   * @param period
   */
  public async createOrUpdatePresenceTrackingReports(period: Date) {
    for (const participant of this.participants) {
      await participant.createOrUpdatePresenceTrackingReport(period);
    }
  }

  /**
   * Tells us whether the course is active nor not.
   *
   */
  public isActive() {
    return this.startsAt < dayjs() && this.endsAt > dayjs();
  }

  /**
   * Tells us whether the course will start later nor not.
   *
   */
  public willStartLater() {
    return this.startsAt > dayjs();
  }

  /**
   * Tells us whether the course is expired or not.
   *
   */
  public isCompleted() {
    return this.endsAt < dayjs();
  }

  /**
   * Returns a list of dates between the start and end date of the course.
   *
   */
  public getListOfMonths() {
    const dates = [];
    const countMonths = this.endsAt.diff(this.startsAt, "month");

    for (let i = 0; i <= countMonths; i++) {
      dates.push(this.startsAt.add(i, "month"));
    }

    return dates;
  }
}
