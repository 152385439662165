import React from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// MUI
import { DataGridPro, gridClasses, GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import CheckIcon from "@mui/icons-material/Check";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

// TS-Hub
import { CourseBookRecord } from "../../../../models/courseBookRecord";
import { CourseBookRecordsTablePanelContent } from "./courseBookRecordsTablePanelContent";

type CourseBookRecordsTableProps = {
  entries: Array<CourseBookRecord>;
  pageSize: number;
  toolbar?: React.ReactNode | React.FC;
  refreshCourseBook: Function;
};

export const CourseBookRecordsTable: React.FC<CourseBookRecordsTableProps> = props => {
  const { t } = useTranslation();
  const { entries, refreshCourseBook, pageSize } = props;
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 20 },
    {
      field: "isApproved",
      headerName: "Genehmigt",
      minWidth: 30,
      sortable: false,
      renderCell: params => (params.row.isApproved ? <CheckIcon color={"success"} /> : <WarningAmberIcon color={"warning"} />)
    },
    {
      field: "teachingDay",
      headerName: t("COMMON.WORDS.TeachingDay") || "",
      width: 200,
      valueGetter: params => dayjs(params.row.teachingDay).format("DD.MM.YYYY, dddd"),
      sortable: false
    },
    {
      field: "teacher",
      headerName: t("COMMON.WORDS.Teacher") || "",
      minWidth: 200,
      sortable: false,
      valueGetter: params => (params.row.teacher ? params.row.teacher.getFullName() : "")
    },
    { field: "topic",
       headerName: t("COMMON.WORDS.Topic") || "",
       width: 300,
       sortable: false },
    { 
      field: "entry", 
      headerName: t("COMMON.WORDS.Entry") || "", 
      width: 100, 
      flex: 1,
      sortable: false,
      renderCell: params => (
        <div style={{ whiteSpace: 'pre-wrap', lineHeight: '1' }}>
          {params.row.entry}
        </div>
      )
    }
  ];

  return (
    <DataGridPro
      rows={entries}
      columns={columns}
      pageSizeOptions={[5, 10, 20, 100]}
      checkboxSelection={false}
      autoHeight={true}
      hideFooter={false}
      hideFooterPagination={false}
      pagination={true}
      paginationMode={"client"}
      getRowHeight={() => "auto"}
      sx={{
        [`& .${gridClasses.cell}`]: {
          py: 1
        },
        border: "none"
      }}
      initialState={{
        columns: { columnVisibilityModel: { id: false } },
        pagination: { paginationModel: { pageSize: pageSize } }
      }}
      getDetailPanelContent={({ row }: GridRowParams) => (
        <CourseBookRecordsTablePanelContent record={row} refreshCourseBook={refreshCourseBook} />
      )}
      getDetailPanelHeight={() => "auto"}
    />
  );
};
