import { AxiosResponse } from "axios";
import { dispatch } from "./http";

import { Course, ICourse, ICoursePerformanceReport, ICoursesPerformanceReport } from "../models/course";
import { ICourseBookRecordCreate, ICourseBookRecordUpdate } from "../models/courseBookRecord";
import { CourseBook, ICompletionCheck } from "../models/courseBook";
import { TutoringBook } from "../models/tutoringBook";
import { ITutoringBookRecord } from "../models/tutoringBookRecord";

export class ApiClassesService {
  // *************************************************************************** //
  // ************************* C O U R S E ************************************* //
  // *************************************************************************** //
  public static fetchAllCourses(pageParam: URLSearchParams): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: "/api/v1/courses/",
      params: pageParam
    });
  }

  public static fetchCoursesPerformanceReport(): Promise<AxiosResponse<ICoursesPerformanceReport>> {
    return dispatch({
      method: "GET",
      url: `/api/v1/courses/performance-report/`
    });
  }

  public static fetchCourse(id: string): Promise<AxiosResponse<ICourse>> {
    return dispatch({
      method: "GET",
      url: `/api/v1/courses/${id}/`
    });
  }

  public static patchCourse(id: string, body: ICourse): Promise<AxiosResponse<Course>> {
    return dispatch({
      method: "PATCH",
      url: `/api/v1/courses/${id}/`,
      data: body
    });
  }

  public static fetchCourseBookFromCourse(id: string): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: `/api/v1/courses/${id}/course-book/`
    });
  }

  public static fetchCoursePerformanceReport(id: string): Promise<AxiosResponse<ICoursePerformanceReport>> {
    return dispatch({
      method: "GET",
      url: `/api/v1/courses/${id}/performance-report/`
    });
  }

  public static createCoursePresenceReports(id: string, month: string, year: string): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: `/api/v1/courses/${id}/presence-reports/`,
      params: new URLSearchParams({ month, year }),
      responseType: "blob"
    });
  }

  public static fetchCoursePerformanceQuarterReports(id: string, quarter: number): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: `/api/v1/courses/${id}/performance-quarter-reports/`,
      params: new URLSearchParams({ quarter: String(quarter) }),
      responseType: "blob"
    });
  }

  // *************************************************************************** //
  // ************************* C O U R S E B O O K  **************************** //
  // *************************************************************************** //
  public static fetchCourseBook(id: number | string): Promise<AxiosResponse<CourseBook>> {
    return dispatch({
      method: "GET",
      url: `/api/v1/course-books/${id}/`
    });
  }

  public static downloadCourseBook(id: number, month: string, year: string): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: `/api/v1/course-books/${id}/download/`,
      params: new URLSearchParams({ month, year }),
      responseType: "blob"
    });
  }

  public static checkCompletion(id: number, month: string, year: string): Promise<AxiosResponse<ICompletionCheck>> {
    return dispatch({
      method: "GET",
      url: `/api/v1/course-books/${id}/completion-check/`,
      params: new URLSearchParams({ month, year })
    });
  }

  // *************************************************************************** //
  // ************************* C O U R S E B O O K R E C O R D ***************** //
  // *************************************************************************** //
  public static createCourseBookRecord(data: ICourseBookRecordCreate): Promise<AxiosResponse> {
    return dispatch({
      method: "POST",
      url: `/api/v1/course-book-records/`,
      data: {
        ...data,
        teachingDay: data.teachingDay?.format("DD.MM.YYYY")
      }
    });
  }

  public static deleteCourseBookRecord(id: number): Promise<AxiosResponse> {
    return dispatch({
      method: "DELETE",
      url: `/api/v1/course-book-records/${id}/`
    });
  }

  public static updateCourseBookRecord(id: number, data: ICourseBookRecordUpdate): Promise<AxiosResponse> {
    return dispatch({
      method: "PATCH",
      url: `/api/v1/course-book-records/${id}/`,
      data: {
        entry: data.entry,
        teaching_day: data.teachingDay?.format("DD.MM.YYYY"),
        teaching_units: data.teachingUnits
      }
    });
  }

  public static approveCourseBookRecord(id: number): Promise<AxiosResponse> {
    return dispatch({
      method: "POST",
      url: `/api/v1/course-book-records/${id}/approve/`
    });
  }

  // *************************************************************************** //
  // ************************ T U T O R I N G B O O K  ************************* //
  // *************************************************************************** //
  public static fetchTutoringBook(id: number | string): Promise<AxiosResponse<TutoringBook>> {
    return dispatch({
      method: "GET",
      url: `/api/v1/tutoring-books/${id}/`
    });
  }

  // *************************************************************************** //
  // ****************** T U T O R I N G B O O K R E C O R D ******************** //
  // *************************************************************************** //
  public static createTutoringBookRecord(data: ITutoringBookRecord): Promise<AxiosResponse> {
    return dispatch({
      method: "POST",
      url: `/api/v1/tutoring-book-records/`,
      data: {
        ...data,
        teachingDay: data.teachingDay?.format("DD.MM.YYYY")
      }
    });
  }
}
