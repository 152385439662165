import dayjs, { Dayjs } from "dayjs";
import { IUser, User } from "./user";

export interface ICourseBookRecord {
  id?: number | null;
  module: number;
  courseBook: number;
  topic: string;
  entry: string;
  teachingDay: Dayjs | null;
  teacher?: User | null;
  teachersFirstName: string | null;
  teachersLastName: string | null;
  teachingUnits: number;
  approver?: User | null;
  approverFirstName: string | null;
  approverLastName: string | null;
  approvedAt: Dayjs | null;
  isApproved: boolean;
  createdAt: Dayjs;
}

export interface ICourseBookRecordCreate {
  id?: number | null;
  module: number;
  courseBook: number;
  topic: string;
  entry: string;
  teachingDay: Dayjs | null;
  teachingUnits: number;
}

export interface ICourseBookRecordUpdate {
  entry: string;
  teachingDay: Dayjs | null;
  teachingUnits: number;
}

export class CourseBookRecord implements ICourseBookRecord {
  id?: number | null;
  module: number;
  courseBook: number;
  topic: string;
  entry: string;
  teachingDay: Dayjs;
  teacher: User | null;
  teachersFirstName: string | null;
  teachersLastName: string | null;
  teachingUnits: number;
  approver?: User | null;
  approverFirstName: string | null;
  approverLastName: string | null;
  approvedAt: Dayjs | null;
  isApproved: boolean;
  createdAt: Dayjs;

  constructor(data: ICourseBookRecord) {
    this.id = data.id || null;
    this.module = data.module;
    this.courseBook = data.courseBook;
    this.topic = data.topic || "";
    this.entry = data.entry || "";
    this.teachingDay = dayjs(data.teachingDay || "");
    this.teacher = data.teacher ? new User(data.teacher as IUser) : null;
    this.teachersFirstName = data.teachersFirstName || null;
    this.teachersLastName = data.teachersLastName || null;
    this.teachingUnits = data.teachingUnits;
    this.approver = data.approver ? new User(data.approver as IUser) : null;
    this.approverFirstName = data.approverFirstName || null;
    this.approverLastName = data.approverLastName || null;
    this.approvedAt = dayjs(data.approvedAt || "");
    this.isApproved = data.isApproved;
    this.createdAt = dayjs(data.createdAt || "");
  }
}
